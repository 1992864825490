var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"middle-header"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row logo-row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"navbar-brand"},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('img',{attrs:{"src":_vm.imgUrl('brand.png'),"alt":""}})])],1)])])])]),_c('div',{staticClass:"top-header isMobile header-usp"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('ClientOnly',[_c('div',{staticClass:"header-fade-slider"},[_c('div',{staticClass:"header-slider-wrap"},[_c('VueSlickCarousel',{attrs:{"arrows":false,"dots":false,"slidesToShow":3,"autoplay":false,"responsive":[
                    {
                      breakpoint: 1199,
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 1,
                        autoplay: true,
                      },
                    },
                    {
                      breakpoint: 540,
                      settings: {
                        slidesToShow: 1,
                        autoplay: true,
                      },
                    } ]}},_vm._l((_vm.informativeSlider.slides),function(slide,index){return _c('div',{key:index,staticClass:"cms-block-content"},[_c('div',{domProps:{"innerHTML":_vm._s(slide.description)}})])}),0)],1)])])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }