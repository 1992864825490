<template>
  <div class="header-text-wrap">
    <div class="header-text isMobile">
      <div class="container">
        <div class="row text-row">
          <div class="col col-md-12">
            <p class="mb-0 regular-15 text-center">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="header-text top-header isDesktop">
      <div class="container">
        <div class="row">
          <div class="col col-md-12">
            <HeaderTopIcons
              showType="1"
              @show-search-m="handleSearchMobile(true)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTopIcons from "@/esf_antarctica_feestenkleding/core/components/header/HeaderTopIcons.vue";

export default {
  components: {
    HeaderTopIcons,
  },
};
</script>

<style>
</style>