<template>
  <diV class="top-header-wrap">
    <div class="top-header isMobile">
      <div class="container">
        <div class="mobile-search-bar" :class="{ show: showSearchMobile }">
          <HeaderSearch @close-search-m="handleSearchMobile(false)" />
        </div>
        <div class="row align-items-center">
          <div class="col-md-4 col-2">
            <div class="menu-trigger-wrap">
              <button @click="$emit('ham-toggle')" class="menu-trigger btn text-white regular-22">
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="19" viewBox="0 0 27 19">
                  <g>
                    <g>
                      <path d="M1.5 9.5h24m-24 8h24m-24-16h24" />
                      <path fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="20" stroke-width="2"
                        d="M1.5 9.5h24m-24 8h24m-24-16h24" />
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div class="col-md-8 col-10">
            <HeaderTopIcons showType="O" @show-search-m="handleSearchMobile(true)" />
          </div>
        </div>
      </div>
    </div>

    <div class="top-header isDesktop">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-12">
            <ClientOnly>
              <div class="header-fade-slider">
                <div class="header-line-wrap">
                  <div class="cms-block-content" v-for="(slide, index) in informativeSlider.slides" :key="index"
                    v-html="slide.description"></div>
                </div>
              </div>
            </ClientOnly>
          </div>
        </div>
      </div>
    </div>
  </diV>
</template>

<script>
import HeaderTopIcons from "@/esf_antarctica_feestenkleding/core/components/header/HeaderTopIcons.vue";
import HeaderSearch from "@/base/core/components/header/HeaderSearch.vue";
import ClientOnly from "vue-client-only";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: {
    ClientOnly,
    HeaderTopIcons,
    HeaderSearch,
  },
  data: () => ({
    showSearchMobile: false,
    carouselConf: {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 600,
          settings: {
            autoplay: true,
            fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    },
  }),
  computed: {
    informativeSlider() {
      const data =
        this.$store.getters["sliders/getSliderByIdentifier"](
          "informative_slider"
        );
      return data;
    },
  },
  methods: {
    handleSearchMobile(value) {
      this.showSearchMobile = value;
    },
  },
};
</script>

<style>
</style>