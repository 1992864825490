<template>
  <header class="header" id="Header">
    <at-header-top @ham-toggle="toggleHamView"></at-header-top>
    <at-header-text></at-header-text>
    <at-header-middle></at-header-middle>
    <Navbar />
    <div
      :class="{ 'overlay-menu': isHamVisible }"
      @click="isHamVisible = false"
    ></div>
    <div :class="{ 'slide-fade': isHamVisible }">
      <SidebarMenu @ham-toggle="toggleHamView"></SidebarMenu>
    </div>
  </header>
</template>

<script>
import AtHeaderTop from "@/esf_antarctica_feestenkleding/core/components/header/HeaderTop.vue";
import AtHeaderText from "@/esf_antarctica_feestenkleding/core/components/header/HeaderText.vue";
import AtHeaderMiddle from "@/esf_antarctica_feestenkleding/core/components/header/HeaderMiddle.vue";
import Navbar from "@/esf_antarctica_feestenkleding/core/components/header/NavBar.vue";
import SidebarMenu from "@/base/core/components/sidebar/SidebarMenu.vue";

export default {
  components: {
    AtHeaderTop,
    AtHeaderText,
    AtHeaderMiddle,
    Navbar,
    SidebarMenu,
  },

  data() {
    return {
      isHamVisible: false,
    };
  },

  methods: {
    toggleHamView() {
      // this.$store.commit("home/updateOverlay");
      this.isHamVisible = !this.isHamVisible;
    },
  },
};
</script>

<style>
</style>