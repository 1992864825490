<template>
  <div>
    <div class="middle-header">
      <div class="container">
        <div class="row logo-row">
          <div class="col-12 col-md-12">
            <div class="navbar-brand">
              <router-link :to="{ name: 'home' }">
                <img :src="imgUrl('brand.png')" alt="" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="top-header isMobile header-usp">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-12">
            <ClientOnly>
              <div class="header-fade-slider">
                <div class="header-slider-wrap">
                  <VueSlickCarousel
                    :arrows="false"
                    :dots="false"
                    :slidesToShow="3"
                    :autoplay="false"
                    :responsive="[
                      {
                        breakpoint: 1199,
                        settings: {
                          slidesToShow: 2,
                        },
                      },
                      {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 1,
                          autoplay: true,
                        },
                      },
                      {
                        breakpoint: 540,
                        settings: {
                          slidesToShow: 1,
                          autoplay: true,
                        },
                      },
                    ]"
                  >
                    <div
                      class="cms-block-content"
                      v-for="(slide, index) in informativeSlider.slides"
                      :key="index"
                    >
                      <div v-html="slide.description"></div>
                    </div>
                  </VueSlickCarousel>
                </div>
              </div>
            </ClientOnly>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getImageUrl from "@/base/helpers/getImageUrl.js";
import VueSlickCarousel from "vue-slick-carousel";
import ClientOnly from "vue-client-only";

export default {
  components: {
    VueSlickCarousel,
    ClientOnly,
  },

  data() {
    return {
      carouselConf: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,

        responsive: [
          {
            breakpoint: 600,
            settings: {
              autoplay: true,
              fade: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      },
    };
  },
  methods: {
    imgUrl(fileName) {
      return getImageUrl(fileName);
    },
  },
  computed: {
    informativeSlider() {
      const data =
        this.$store.getters["sliders/getSliderByIdentifier"](
          "informative_slider"
        );
      return data;
    },
  },
};
</script>


<style>
</style>