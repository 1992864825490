<template>
  <div>
    <span class="mobile-menu-header d-lg-none">{{
      $t("navbar_choose_category")
    }}</span>
    <b-link class="nav-opener inner-close" @click="toggleNav"></b-link>
    <ul class="nav-menu">
      <li class="nav-item">
        <span class="nav-link"
          ><b-link to="/">{{ $t("home") }}</b-link></span
        >
      </li>
      <li
        class="nav-item"
        v-for="navItem of navItems"
        :key="navItem.id"
        @mouseover="onOver(navItem.id)"
        @mouseleave="onLeave(navItem.id)"
      >
        <div class="nav-link" @click="onToggle(navItem.id)">
          <ClientOnly>
            <b-link to="#" @click.stop="onClick(navItem.id, navItem.linkTo)">{{
              navItem.itemName
            }}</b-link>
            <i class="fas fa-caret-down" v-if="navItem.dropMenu.length > 0"></i>
          </ClientOnly>
        </div>

        <HeaderSubmenu
          v-if="navItem.hasDropMenu"
          :menuArray="navItem.dropMenu"
          :collapseId="'submenu' + navItem.id"
          :id="navItem.id"
        />
      </li>
      <cmsBlockSimple
        v-if="typeof staticMenu == 'object'"
        :identifier="staticMenu.identifier"
      />
      <!-- <li class="nav-item">
        <b-link to="/sale">{{ $t("sale") }}</b-link>
      </li> -->
    </ul>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";
import HeaderSubmenu from "@/base/core/components/header/HeaderSubmenu.vue";
import ClientOnly from "vue-client-only";
import cmsBlockSimple from "@/base/core/components/common/BlockSimple.vue";
export default {
  name: "Navbar",
  components: {
    HeaderSubmenu,
    ClientOnly,
    cmsBlockSimple,
  },
  data: () => ({}),
  computed: {
    navItems() {
      return this.$store.getters["menu/getMenuItems"].slice(0, 6);
    },
    staticMenu() {
      var menuData =
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "header_menu_static"
        );
      menuData.content = menuData.content
        .replace(/<\/?ul>/, "")
        .replace("<li>", '<li class="nav-item"><div class="nav-link">')
        .replace("</b-link></li>", "</b-link></div></li>")
        .replace("</a></li>", "</a></div></li>");
      Logger.debug("static menu", "navbar", menuData)();
      return menuData;
    },
  },
  methods: {
    onOver(id) {
      if (window.innerWidth > 991) {
        this.$store.commit("menu/updateMenuState", { id: id, state: true });
      }
    },
    onToggle(id) {
      if (window.innerWidth <= 991) {
        const vis = this.$store.getters["menu/getMenuItemState"](id);
        Logger.debug("onToggle id", "Navbar", vis.id)();
        Logger.debug("onToggle", "Navbar", vis.state)();

        if (vis.state == false) {
          this.$store.commit("menu/updateMenuState", { id: id, state: true });
        } else {
          this.$store.commit("menu/updateMenuState", { id: id, state: false });
        }
      }
    },
    onLeave(id) {
      if (window.innerWidth > 991) {
        this.$store.commit("menu/updateMenuState", { id: id, state: false });
      }
    },
    doMouseOver(id) {
      Logger.debug("Mouse Over", "Menu", id)();
    },
    onClick(id, route) {
      Logger.debug("onClick", "navbarContent", id)();
      Logger.debug("onClick", "navbarContent", route)();
      Logger.debug(
        "onClick menu item state",
        "navbarContent",
        this.$store.getters["menu/getMenuItemState"](id).state
      )();
      this.$store.commit("menu/updateMenuState", { id: id, state: false });
      Logger.debug(
        "onClick menu item state",
        "navbarContent",
        this.$store.getters["menu/getMenuItemState"](id).state
      )();
      this.$store.commit("menu/setNavBar", false);

      this.$router.push(route);
    },
    toggleNav(event) {
      this.$emit("clicked", event);
    },
  },
  
};
</script>

<style lang="scss" scoped>
</style>
